import { Fragment } from 'react';
import FavoriteIcon from '@stageplus/icons/react/favorite';
import FavoriteOutlineIcon from '@stageplus/icons/react/favorite-outline';
import InfoIcon from '@stageplus/icons/react/info';
import MoreIcon from '@stageplus/icons/react/more';
import PlayOutlineIcon from '@stageplus/icons/react/play-outline';
import ReminderOutlineIcon from '@stageplus/icons/react/reminder-outline';
import ShareIcon from '@stageplus/icons/react/share';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { ButtonIconOnly } from 'src/components/buttons/icon-button';
import ContextMenu from 'src/components/context-menu';
import { useFavorite } from 'src/hooks/use-favorite';
import {
  useInfoModal,
  useReminderModal,
  useShareModal,
  InfoModalData,
  ShareModalData,
  ReminderModalData,
} from 'src/hooks/use-modal';
import usePermissions from 'src/hooks/use-permissions';
import useTranslate from 'src/hooks/use-translate';
import { usePlayback } from 'src/state/playback';
import { PlayableMedia } from 'src/types';
import { FavoritableNode } from 'src/utilities/favorite-helpers';
import { getPlayerPath } from 'src/utilities/url-helpers';

type MoreMenuNode = NonNullable<InfoModalData | ShareModalData | ReminderModalData | FavoritableNode>;
type MoreMenuProps = {
  node: MoreMenuNode;
};

function ReminderMenuItem({ node }: { node: ReminderModalData }) {
  const t = useTranslate();
  const { open: openReminderModal } = useReminderModal();

  const handleClick = () => openReminderModal(node);

  return (
    <ContextMenu.Item icon={ReminderOutlineIcon} onClick={handleClick} data-test="more-menu-reminder">
      {t('contextmenu__set_reminder')}
    </ContextMenu.Item>
  );
}
function InfoMenuItem({ node }: { node: InfoModalData }) {
  const t = useTranslate();
  const { open: openInfoModal } = useInfoModal();

  const handleClick = () => openInfoModal(node);

  return (
    <ContextMenu.Item icon={InfoIcon} onClick={handleClick} data-test="more-menu-info">
      {t('contextmenu__show_details')}
    </ContextMenu.Item>
  );
}

function ShareMenuItem({ node }: { node: ShareModalData }) {
  const t = useTranslate();
  const { open: openShareModal } = useShareModal();

  const handleClick = () => openShareModal(node);

  return (
    <ContextMenu.Item icon={ShareIcon} onClick={handleClick} data-test="more-menu-share">
      {t('contextMenu__share_this_item')}
    </ContextMenu.Item>
  );
}

function PlayMenuItem({ node, trackId }: { node?: PlayableMedia; trackId?: string }) {
  const t = useTranslate();
  const { currentActiveTrack, currentActiveWork } = usePlayback();
  const router = useRouter();
  const { checkCanPlayMedia, openRequiredModalsToPlayMedia } = usePermissions();
  // check if the track/work is already loaded in the player
  const trackIsActive = trackId && (currentActiveTrack?.id === trackId || currentActiveWork?.id === trackId);
  //  if there's no node or the track is already loaded, we won't show the play button
  if (!node || trackIsActive) {
    return null;
  }

  const handleClick = async () => {
    // check if user is allowed to play the stream
    if (checkCanPlayMedia(node)) {
      // change the player page
      await router.push(getPlayerPath(node, trackId));
    } else {
      // otherwise open an auth modal
      openRequiredModalsToPlayMedia(node);
    }
  };

  return (
    <ContextMenu.Item icon={PlayOutlineIcon} onClick={handleClick} data-test="more-menu-play">
      {t('contextMenu__play_this_item')}
    </ContextMenu.Item>
  );
}

function FavoriteMenuItem({ node }: { node: FavoritableNode }) {
  const t = useTranslate();
  const { isFavorite, isLoading, toggleFavorite } = useFavorite(node);

  if (isLoading) {
    return null;
  }

  return (
    <ContextMenu.Item
      icon={isFavorite ? FavoriteIcon : FavoriteOutlineIcon}
      onClick={toggleFavorite}
      data-test="more-menu-favorite"
    >
      {isFavorite ? t('contextmenu__remove_favorite') : t('contextmenu__add_favorite')}
    </ContextMenu.Item>
  );
}

// if menu item is clicked, make sure we are not triggering any additional clicks
const preventEventBubbling = (event: React.MouseEvent) => {
  event.stopPropagation();
  event.preventDefault();
};

/**
 * A MoreMenu displays a "···" button that opens a menu with a list of choices based on the current node.
 */
export default function MoreMenu({ node }: MoreMenuProps) {
  const t = useTranslate();

  // Reminders are only available for LiveConcerts
  const showReminderOption = node.__typename === 'LiveConcert';
  // Share is only available for Albums, LiveConcerts, VodConcerts, and Videos
  const showShareOption =
    node.__typename === 'Album' ||
    node.__typename === 'Track' ||
    node.__typename === 'LiveConcert' ||
    node.__typename === 'VodConcert' ||
    node.__typename === 'PerformanceWork' ||
    node.__typename === 'Video';
  // Info is only available for Albums, LiveConcerts, VodConcerts, and Videos
  const showInfoOption =
    node.__typename === 'Album' ||
    node.__typename === 'LiveConcert' ||
    node.__typename === 'Track' ||
    node.__typename === 'VodConcert' ||
    node.__typename === 'Video';
  const showFavoriteOption = node.__typename === 'Album' || node.__typename === 'PerformanceWork';
  const showPlayOption =
    node.__typename === 'Track' || node.__typename === 'Album' || node.__typename === 'PerformanceWork';

  const trackId =
    (node.__typename === 'Track' ? node.id : undefined) ||
    (node.__typename === 'PerformanceWork' ? node.id : undefined);

  return (
    <div onClick={preventEventBubbling} className="flex items-center" data-test="more-menu">
      <ContextMenu>
        <ContextMenu.Button as={Fragment} data-test="more-menu-button">
          {({ open }) => (
            <ButtonIconOnly
              title={t('icon__label_more')}
              className={clsx(
                'size-11 transform-gpu rounded-full bg-white/0 transition duration-150 hover:bg-white/10 hover:text-brand-dg',
                open ? 'bg-white/10 text-brand-dg' : 'text-textSecondary',
              )}
              active={open}
              icon={<MoreIcon aria-hidden />}
            />
          )}
        </ContextMenu.Button>
        <div>
          <ContextMenu.Items>
            {showFavoriteOption && <FavoriteMenuItem node={node as FavoritableNode} />}
            {showReminderOption && <ReminderMenuItem node={node as ReminderModalData} />}
            {showPlayOption && <PlayMenuItem node={node as PlayableMedia} trackId={trackId} />}
            {showShareOption && <ShareMenuItem node={node as ShareModalData} />}
            {showInfoOption && <InfoMenuItem node={node as InfoModalData} />}
          </ContextMenu.Items>
        </div>
      </ContextMenu>
    </div>
  );
}
